import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'

import store from './store'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard, faMagnifyingGlass, faSync, faCaretSquareDown, faClose, faUnlock, faUnlockAlt } from '@fortawesome/free-solid-svg-icons'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import mgr from './services/SecurityService';

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Vuex)

library.add(faHatWizard)
library.add(faMagnifyingGlass)
library.add(faSync)
library.add(faCaretSquareDown)
library.add(faClose)
library.add(faUnlock)
library.add(faUnlockAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta && record.meta.requiresAuth);
  const requiresStaff = to.matched.some(record => record.meta && record.meta.requiresStaff);
  if (requiresAuth) {
      mgr.getRole().then(
        sucess => {
          if (to.meta.role == sucess){
            next();
          }else {
            next('/accessdenied');
          }
        },
        err => {
          console.log(err);
        }
      );    
  } else if (requiresStaff){
    mgr.getUser()
      .then(user => {
        if (user && user.profile && user.profile.is_staff){
          next();
        } else {
          next('/accessdenied');
        }
      })
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

