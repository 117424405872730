const user = JSON.parse(localStorage.getItem('user'));

const auth = {
  namespaced: true,
  state: () => ({
      loggedIn: user!=null,
      refreshingCall: null,
      isRefreshing: false,
      user: user,
      token: user==null ? null : user.access
  }),
  mutations: {
    setToken(state, token){
        state.token = token;
        state.loggedIn = true;
    },
    setUser(state, user){
      state.token = user.access;
      state.loggedIn = true;
      state.user = user;
  },    
    setRefreshingState(state, isRefreshing) {
      state.isRefreshing = isRefreshing;
    },
    setRefreshingCall(state, refreshingPromise) {
        state.refreshingCall = refreshingPromise;
      },
    logout(state){
      state.loggedIn= false;
      state.refreshingCall= false;
      state.isRefreshing= false;
      state.user=null;
      state.token=null;
    }
  }
};


export default auth;