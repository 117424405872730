<template>
  <div class="home">
    <b-row class="justify-content-md-center my-3">
      <b-col cols=10 sm=8 md=6 lg=4 >
        <b-img fluid alt="Vue logo" src="/img/logo.png"></b-img>
      </b-col>
    </b-row>
    <div v-if="user!=null">
      <h1>Bienvenue {{user.given_name}}</h1>
      <p>Tu peux accéder aux fonctions suivantes : </p>
      <ul class="justify-content-md-center">
        <li v-if="user.is_staff"><router-link to="/proximity">Administration locale</router-link></li>
      </ul>
    </div>
    <!-- <api-tester/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import BleTester from '@/components/BleTester.vue'
// import ApiTester from '@/components/ApiTester.vue'
import Mgr from '../services/SecurityService';

export default {
  
  name: 'HomeView',
  data: () => {
    return {
      mgr : Mgr,
      user: null,
      signedIn: null,
    }
  },
  components: {
    // ApiTester,
  },
  computed:{

  },
  mounted () {
      this.mgr.getSignedIn().then(
        signIn => {
          this.signedIn = signIn;
        },
        err => {
          console.log(err);
        }
      )    
  },
  watch:{
    signedIn(newval){
      if (newval){
        this.mgr.getUser()
          .then(user=>{
            if (user && user.profile){
              this.user=user.profile;
            }            
          })
          .catch(err=>{
            console.log(err);
          })
      }
    }
  },
  methods:{
  }
}
</script>
