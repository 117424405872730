<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link> |
      <template v-if="user&&user.is_staff">
        <router-link to="/proximity">Admin local</router-link> |
      </template>
      <router-link to="/about">About</router-link> |
      <a v-if="user" href="#" @click="mgr.signOut()">Logout</a>
    </nav>
    <router-view/>
  </div>
</template>

<script>
import Mgr from './services/SecurityService'

export default {
    data () {
      return {
        // mgr: new Mgr(),
        mgr: Mgr,
        user: null,
      }    
    },
    mounted(){
      this.mgr.getUser()
        .then(user => {
          this.user = user;
        })
      this.mgr.events.addUserLoaded(function (user) {  
        this.user = user;
      });
      this.mgr.events.addUserSignedOut(function () {
          this.user = null;
        });
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
